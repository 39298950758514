import {RootState} from '../../../store/store';
import {useSelector} from 'react-redux';

function selector(state: RootState) {
    return state.user;
}

export function useUserInfo() {
  return useSelector<RootState, ReturnType<typeof selector>>(selector);
}
