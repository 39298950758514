import React from 'react'
import { addTheme } from '../../../style/addTheme';
import BasicText from '../../basic/BasicText';

interface titleSubItemType{
    text?:string;
    marginTop?:number
    textAlign?:string
}

export default function TitleSubItem({text,marginTop,textAlign}:titleSubItemType) {
    return (
        <BasicText marginTop={marginTop} textAlign={textAlign} fontSize={addTheme.subText.fontSize} mFontSize={addTheme.subText.mFontSize} color={addTheme.subText.color}>
            {text}
        </BasicText>
    )
}
