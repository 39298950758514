import React from 'react'
import styled from 'styled-components';
import HeaderPage from '../../header/page/HeaderPage';
import HomeMainBannerPart from '../part/HomeMainBannerPart';
import HomeOnePart from '../part/HomeOnePart';
import { addTheme } from '../../../style/addTheme';
import HomeEasyConnectPart from '../part/HomeEasyConnectPart';
import HomeBannerPart from '../part/HomeBannerPart';
import HomeBasicPart from '../part/HomeBasicPart';
import HomeTechPart from '../part/HomeTechPart';
import HomePartnerPart from '../part/HomePartnerPart';
import HomeContactPart from '../part/HomeContactPart';
import HomeNewsletterPart from '../part/HomeNewsletterPart';
import FooterPage from '../../footer/page/FooterPage';
import HomeCountPart from '../part/HomeCountPart';

export default function HomePage() {
    return (
        <div>
            <HeaderWrap>
                <HeaderPage></HeaderPage>
            </HeaderWrap>
            <HomeMainBannerPart></HomeMainBannerPart>
            <HomeContentWrap>
                <MarginWrap margin='100px 0 0 0'></MarginWrap>
                <HomeOnePart></HomeOnePart>
                <MarginWrap margin='300px 0 0 0' mMargin={'200px 0 0 0'}></MarginWrap>
                <HomeEasyConnectPart></HomeEasyConnectPart>
                <MarginWrap margin='150px 0 0 0'></MarginWrap>
                <HomeBannerPart></HomeBannerPart>
                <MarginWrap margin='250px 0 0 0'></MarginWrap>
                <HomeBasicPart></HomeBasicPart>
                <MarginWrap margin='250px 0 0 0'></MarginWrap>
                <HomeTechPart></HomeTechPart>
                <MarginWrap margin='250px 0 0 0'></MarginWrap>
                <HomeCountPart></HomeCountPart>
                <MarginWrap margin='250px 0 0 0'></MarginWrap>
                <HomePartnerPart></HomePartnerPart>
                <MarginWrap margin='250px 0 0 0'></MarginWrap>
                <HomeContactPart></HomeContactPart>
                <HomeNewsletterPart></HomeNewsletterPart>
            </HomeContentWrap>
            <FooterPage></FooterPage>
        </div>
    )
}

const HeaderWrap = styled.div`
    position: fixed;
    width: 100%;
    left:0;
    top:0;
    z-index: 5;
`;

const HomeContentWrap = styled.div`
    border-top: 1px solid #e9e9e9;
    margin-top: 200px;
    border-radius: 150px 150px 0 0;
    @media ${addTheme.media.m}{
        border-radius: 60px 60px 0 0;
    }
`;

const MarginWrap = styled.div<{margin:string,mMargin?:any}>`
    margin: ${props => props.margin ?? 0};
    @media ${addTheme.media.m}{
        margin: ${props => props.mMargin ?? props.margin ?? 0};
    }
`;