import React from 'react'
import styled from 'styled-components'
import { numberWithComma } from '../../../utils/format/number';

interface countItemType{
    mt?:number;
    count: number | string;
    title : string;
}

export default function CountItem({mt,count,title}:countItemType) {
    return (
        <Wrap mt={mt}>
            <CountWrap>
                {numberWithComma(count)}
            </CountWrap>
            <TitleWrap>
                {title}
            </TitleWrap>
        </Wrap>
    )
}

const Wrap = styled.div<{mt?:number}>`
    margin-top: ${props => props.mt ?? 0}px;
`;
const CountWrap = styled.div`
    font-size: 46px;
    font-weight: 800;
`;
const TitleWrap = styled.div`
    margin-top: 20px;
    font-size: 14px;
`;
