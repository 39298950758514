export const TEXT_DATA = [
    {
        // 0
        ko:[
            `세상을 더`,`스마트하게`,`연결하는.`,
            `블록 수`,`연결된 네트워크 수`
        ],
        en:[
            `More of the world`,`Smartly`,`Connecting.`,
            `Number of blocks`,`Number of networks connected`
        ],
    },
    {
        // 1
        ko:[
            `단 하나의`,
            `ATOREUM은 기존의 블록체인이 가지고 있었던 불필요한 연산 방식을 혁신적인 기술력을 통해 해결하고`,
            `AI 시스템 기술인 ATO와 병렬 형식의 Helix-Structure을 통하여 더욱 안정적이고 빠른 네트워크를 구축하였습니다.`,
            `Proof-of-Action(PoA) 합의 알고리즘을 통하여 WEB3.0 생태계에 가장 최적화된 퍼블릭형 블록체인입니다.`,
        ],
        en:[
            `One and Only`,
            `ATOREUM solved the unnecessary computational methods of the existing blockchain through innovative technology `,
            `and built a more stable and faster network through AI system technology ATO and parallel Helix-Structure.`,
            `It is the most optimized public blockchain for the WEB3.0 ecosystem through the Proof-of-Action (PoA) consensus algorithm.`
        ],
    },
    {
        // 2
        ko:[
            `손쉽게`,`연결하고`,`확장하는.`,
            `기업들은 서비스 별로 특화된 블록체인들을 병렬적으로 구축하고 안정적으로 운영할 수 있습니다.`,
            `각 블록체인들은 Atoreum Smart Connection Network(A-SCN) 시스템 기술을 통하여 상호연동되어 뛰어난 확장성을 실현합니다.`,
            `Atoreum의 Hyper-Chain 기술은 개별 블록체인 구축 기술을 넘어 다양한 블록체인들을 아우를 수 있는 멀티블록체인 네트워크인 Block Cloud서비스 구현을 가능하게 합니다.`
        ],
        en:[
            `Easily`,`Connect`,`Expanding.`,
            `Companies can build blockchains specialized for each service in parallel and operate reliably.`,
            `Each blockchain is interconnected through Atrium Smart Connection Network (A-SCN) system technology to achieve superior scalability.`,
            `Atoreum's Hyper-Chain technology enables the implementation of Block Cloud services, a multi-blockchain network that can encompass various blockchain beyond individual blockchain construction technology.`
        ]
    },
    {
        // 3
        ko:[
            `이제 빠른건 기본으로.`,`더욱 중요해진 안전한`,`내 데이터 관리.`,
            `WEB3.0 생태계에 가장 최적화된 Atoreum의 기술력을 기반으로`,
            `이제는 개인이 자산의 데이터를 소유하여 개인정보를 보호하고`,
            `필요한 곳에 제공하여 직접 수익을 얻거나 활용할 수 있습니다.`,
        ],
        en:[
            `It's basic to be fast.`,`More importantly`,`Secure data management.`,
            `Based on Atoreum technology that is most optimized for the WEB3.0 ecosystem`,
            `Individuals now own the data of their assets to protect their privacy`,
            `You can earn or leverage direct revenue by providing direct revenue where you need it.`,
        ]
    },
    {
        // 4
        ko:[
            `이미 입증된`,`기술력`,
            `기존 블록체인 시스템의 큰 한계점이던 복작합 연산을 위한 고성능 컴퓨팅 파워와 그로 인한 엄청난 전력량 소모를 혁신적인 ATOREUM만의 기술력을 통해 획기적으로 줄여 친환경·탄소중립과 생태계 안정을 목표로하는 ESG 시스템을 갖췄습니다.`,
            `또한 입증된 기술력을 기반으로 WEB3.0을 시작하는 팀에게 최적화된 네트워크 구조로 다양한 프로젝트에 맞춰 확장시킬 수 있습니다.`
        ],
        en:[
            `Proven`, `technology`,
            `It has an ESG system aimed at eco-friendly, carbon neutrality, and ecosystem stability by drastically reducing high-performance computing power for complex operation and the resulting huge power consumption through innovative ATOREUM technology.`,
            `And with proven technology, you can scale to fit a variety of projects with a network structure optimized for teams starting WEB3.0.`
        ]
    },
    {
        // 5
        ko:[
            `새로운 시대를`,`ATOREUM에서.`,
            `여러분의 비즈니스에 최적화된 블록체인을 ATOREUM에서 시작해보세요.`
        ],
        en:[
            `A new era`,`at ATOREUM.`,
            `Start your business-optimized blockchain with ATORM.`
        ]
    },
    {
        // 6
        ko:[
            `계속해서 성장하는 ATOREUM`,
            `ATOREUM 블록수`,`ATOREUM 활성 지갑수`,`연결된 네트워크 수`,
        ],
        en:[
            `ATOREUM continues to grow`,
            `Number of ATOREUM blocks`,`ATOREUM Active Wallet Count`,`Number of networks connected`,
        ]
    },
    {
        // 7
        ko:[
            `이메일을 입력해주세요.`,`약관을 체크해주세요.`
        ],
        en:[
            `Please enter your email.`,`Please check the terms and conditions.`
        ]
    }
]
