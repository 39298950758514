import React from 'react'
import BasicText from '../../basic/BasicText'
import { addTheme } from '../../../style/addTheme'

interface titleItemType{
    text:string;
    textAlign?:'center' | 'left' | 'right';
}

export default function TitleItem({text,textAlign}:titleItemType) {
    return (
        <BasicText fontSize={addTheme.BigFontSize} mFontSize={addTheme.mFontSize} fontWeight={700} textAlign={textAlign}>
            {text}
        </BasicText>
    )
}
