import React from 'react'
import { MaxWidthWrap, Row } from '../../../style/basic/commonStyle'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { addTheme } from '../../../style/addTheme';

const SNS = [
    {id:1,link:'https://t.me/suworld_official',img:'/img/sns/1.png'},
    {id:2,link:'https://twitter.com/SUWORLD2030META',img:'/img/sns/2.png'},
    {id:3,link:'https://www.instagram.com/suworld.official/',img:'/img/sns/3.png'},
    {id:4,link:'https://www.youtube.com/@suworldtime',img:'/img/sns/4.png'},
    {id:5,link:'https://blog.naver.com/suboxm',img:'/img/sns/5.png'},
];

const MENU = [
    {id:1, name:'Products', link:''},
    {id:2, name:'Technology', link:''},
    {id:3, name:'Solutions', link:''},
    {id:4, name:'Contact Us', link:''},
]

export default function FooterPage() {
    return (
        <div>
            <MaxWidthWrap maxWidth='1280px' padding='0 30px'>
                <InnerWrap>
                    <LogoWrap>
                        <Link to='/'>
                            <img alt='logo' src='/img/logo.png'></img>
                        </Link>
                        <LogoMenuWrap>
                            
                            <SnsWrap>
                                {SNS.map((data,index)=>{
                                    return(
                                        <a href={data.link} key={`sns-${index}`} target='_blank'>
                                            <img alt='sns-logo' src={data.img}></img>
                                        </a>
                                    )
                                })}
                            </SnsWrap>
                        </LogoMenuWrap>
                    </LogoWrap>
                    <MenuWrap>
                        {/* {MENU.map((data,index)=>{
                            return(
                                <MenuItem key={`f-menu-${index}`}>
                                    <a href={data.link}>{data.name}</a>
                                </MenuItem>
                            )
                        })} */}
                    </MenuWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled(Row)`
    padding: 80px 0;
    align-items: start;
    flex-wrap: wrap;
`;

const LogoWrap = styled.div`
    width: 40%;
    & img{
        max-height: 40px;
        max-width: 100%;
    }
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;

const LogoMenuWrap = styled.div`
    padding-left: 50px;
    font-size: 22px;
    margin-top: 25px;
`;

const SnsWrap = styled(Row)`
    margin-top: 40px;
    & a{
        max-width : 32px;
        margin: 0 6px;
        img{
            width: 100%;
        }
    }
`;

const MenuWrap = styled.div`
    width: 40%;
    @media ${addTheme.media.m}{
        width: 100%;
        margin-top: 25px;
    }
`;
const MenuItem = styled.div`
    margin-top: 20px;
    font-size: 20px;
    &:nth-of-type(1){
        margin-top: 5px;
    }
   
`;