import React from 'react'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle'
import styled from 'styled-components'
import TitleItem from '../../common/item/TitleItem'
import { addTheme } from '../../../style/addTheme'

const LIST = [
    {id:1,img:'/img/partner/logo1.png'},
    {id:2,img:'/img/partner/logo2.png'},
    {id:3,img:'/img/partner/logo3.png'},
    {id:4,img:'/img/partner/logo4.png'},
    {id:5,img:'/img/partner/logo5.png'},
    {id:6,img:'/img/partner/logo6.png'},
    {id:7,img:'/img/partner/logo7.png'},
    {id:8,img:'/img/partner/logo8.png'},
    {id:9,img:'/img/partner/logo9.png'},
    {id:10,img:'/img/partner/logo10.png'},
    {id:11,img:'/img/partner/logo11.png'},
    {id:12,img:'/img/partner/logo12.png'},
    {id:13,img:'/img/partner/logo13.png'},
    {id:14,img:'/img/partner/logo14.png'},
    {id:15,img:'/img/partner/logo15.png'},
]

export default function HomePartnerPart() {
    return (
        <div>
            <MaxWidthWrap>
                <InnerWrap>
                    <Center>
                        <TitleItem text='Clients & Partners'></TitleItem>
                    </Center>
                    <ContentWrap>
                        {LIST.map((data,index)=>{
                            return(
                                <ContentItem key={`partner-${index}`}>
                                    <img alt='partner-logo' src={data.img}></img>
                                </ContentItem>
                            )
                        })}
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled.div``
const ContentWrap = styled(Row)`
    margin-top: 80px;
    flex-wrap: wrap;
`;

const ContentItem = styled.div`
    width: 18%;
    margin: 15px 1%;
    & img{
        max-width: 100%;
    }

    @media ${addTheme.media.m}{
        width: 48%;
    }
`;