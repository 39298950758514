import React from 'react'
import { Center, MaxWidthWrap, Row, RowBetween } from '../../../style/basic/commonStyle';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import HeaderMenuPart from '../part/HeaderMenuPart';
import HeaderLangPart from '../part/HeaderLangPart';

export default function HeaderPage() {
    return (
        <div>
            <MaxWidthWrap maxWidth='1280px' padding='0 30px'>
                <InnerWrap>
                    <Left>
                        <Link to='/'>
                            <img alt='logo' src='/img/logo.png'></img>
                        </Link>
                    </Left>
                    <Right>
                        <MenuPcWrap>
                            <HeaderMenuPart></HeaderMenuPart>
                        </MenuPcWrap>
                        <HeaderLangPart></HeaderLangPart>
                    </Right>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled(RowBetween)`
    height: 70px;
`;
const Left = styled(Center)`
    width: 35%;
    height: 100%;
    /* & a{height:100%;} */
    & img{
        max-height: 34px;
    }
    justify-content: start;
    @media ${addTheme.media.m}{
        & img{
            max-height: 24px;
        }
    }
`;

const Right= styled(Row)`
    justify-content: end;
    width: 65%;
`;

const MenuPcWrap = styled.div`
    width: 100%;
    @media ${addTheme.media.m}{
        display: none;
    }
`;