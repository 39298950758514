import React from 'react'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle'
import styled from 'styled-components';
import TitleItem from '../../common/item/TitleItem';
import TitleSubItem from '../../common/item/TitleSubItem';
import useLang from '../../../utils/custom-hooks/common/useLang';

export default function HomeBasicPart() {
    const txt = useLang(3);

    return (
        <div>
            <MaxWidthWrap>
                <InnerWrap>
                    <TextWrap>
                        <TitleItem text={txt[0]}></TitleItem>
                        <TitleItem text={txt[1]}></TitleItem>
                        <TitleItem text={txt[2]} textAlign={'center'}></TitleItem>
                    </TextWrap>
                    <TextWrap>
                        <TitleSubItem text={txt[3]}></TitleSubItem>
                        <TitleSubItem text={txt[4]}></TitleSubItem>
                        <TitleSubItem text={txt[5]}></TitleSubItem>
                    </TextWrap>
                    <ImgWrap>
                        <img alt='data-img' src='/img/banner_2.png'></img>
                    </ImgWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled.div``;

const TextWrap = styled(Center)`
    flex-direction: column;
    margin-top: 50px;
`;

const ImgWrap = styled.div`
    margin-top: 100px;
    & img{
        width: 100%;
    }
`;