import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { themeColor } from './common/theme';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import session from "redux-persist/lib/storage/session";
import { globalLoadingState } from './common/globalLoading';
import { globalToast } from './common/globalToast';
import { apiInformation } from './api/apiInformation';
import {user} from './common/user';
import { globalDialog } from './common/globalDialog';
import { globalLang } from './common/globalLang';

const persistConfig = {
    key: "ato",
    // localStorage에 저장합니다.
    storage:session,
    whitelist: ['globalLang'],
    blacklist: []
};

const rootReducer = combineReducers({
    themeColor,
    globalLoadingState,
    globalToast,
    apiInformation,
    user,
    globalDialog,
    globalLang
});

export type RootState = ReturnType<typeof rootReducer>;

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
export default function reducer() {
    return persistReducer(persistConfig, rootReducer);
}
