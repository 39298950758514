import React from 'react'
import styled from 'styled-components'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle';
import TitleItem from '../../common/item/TitleItem';
import TitleSubItem from '../../common/item/TitleSubItem';
import { addTheme } from '../../../style/addTheme';
import useLang from '../../../utils/custom-hooks/common/useLang';

const LIST = [
    {id:1,name:'Super AI system',img:'/img/tech/item_1.png'},
    {id:2,name:'Helix-Structure',img:'/img/tech/item_2.png'},
    {id:3,name:'Smart Connection Network',img:'/img/tech/item_3.png'},
    {id:4,name:'Super Security & Speedy',img:'/img/tech/item_4.png'},
    {id:5,name:'Proof-of-Action',img:'/img/tech/item_5.png'},
    {id:6,name:'Blockchain Cloud Service',img:'/img/tech/item_6.png'},
]

export default function HomeTechPart() {
    const txt = useLang(4);
    return (
        <div>
            <MaxWidthWrap>
                <InnerWrap>
                    <TitleItem text={txt[0]}></TitleItem>
                    <TitleItem text={txt[1]}></TitleItem>
                    <SubTextWrap>
                        <TitleSubItem text={txt[2]}></TitleSubItem>
                        <TitleSubItem text={txt[3]}></TitleSubItem>
                    </SubTextWrap>
                    <ItemWrap>
                        {LIST.map((data,index)=>{
                            return(
                                <Item key={`tech-${index}`}>
                                    <ItemImgWrap>
                                        <img alt='tech-img' src={data.img}></img>
                                    </ItemImgWrap>
                                    <ItemTextWrap>
                                        {data.name}
                                    </ItemTextWrap>
                                </Item>
                            )
                        })}
                    </ItemWrap>
                    <BtnWrap>
                        <Btn>ATOREUM Technology</Btn>
                    </BtnWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled.div``;
const SubTextWrap = styled.div`
    margin-top: 30px;
`;

const ItemWrap = styled(Row)`
    flex-wrap: wrap;
`;

const Item = styled.div`
    width: 32.33%;
    margin:20px 0.5%;
    @media ${addTheme.media.m}{
        width: 49%;
    }
`;

const ItemImgWrap = styled.div`
    width: 100%;
    overflow: hidden;
    & img{
        width: 100%;
        border-radius: 10px;
        /* border: 1px solid #7f7f77; */
    }
`;
const ItemTextWrap = styled(Center)`
    margin: 10px 0 0;
    font-size: 19px;
    font-weight: 700;
    min-height: 38px;
    @media ${addTheme.media.m}{
        font-size: 17px;
    }
`;

const BtnWrap = styled(Center)`
    margin-top: 65px;
`;

const Btn = styled.button`
    background-color: #00359a;
    color: #fff;
    padding: 20px 30px;
`;