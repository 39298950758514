export const addTheme = {
    media:{
        m:'(max-width: 992px)',
        s:'(max-width:720px)'
    },
    maxWidth:'1120px',
    padding:'0 15px',
    headerHeight:'70px',
    BigFontSize:44,
    mFontSize:33,
    subText:{
        color:'#ababab',
        fontSize:19,
        mFontSize:15,
    },
}