import {css} from 'styled-components';

const InputTypeSearchHidden = css`
  input::-ms-clear,
  input::-ms-reveal {
    display:none;width:0;height:0;
  }
  input::-webkit-search-decoration,
  input::-webkit-search-cancel-button,
  input::-webkit-search-results-button,
  input::-webkit-search-results-decoration {
    display:none;
  }
`;

const InputTypeNumberHidden = css`
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const MyReset = css`
  ${InputTypeSearchHidden};
  ${InputTypeNumberHidden};
  
  *, *:before, *:after {
    padding: 0;
    border: none;
    background-color: transparent;  
    box-sizing: border-box;
    font-size: inherit;
    margin: 0;
  }
  
  table {
    width: 100%; 
    table-layout: fixed;
  }
  
  td {
    vertical-align: middle;
  }
  
  b, h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }
  
  a {
    text-decoration: none;
  }
  
  button, select {
    cursor: pointer;
  }
  
  input, button {
    &:disabled {
      cursor: not-allowed;
    }
  }
  
  textarea {
    resize: none;
  }
`;
