import React,{useState,useEffect, useRef} from 'react'
import styled from 'styled-components';
import { addTheme } from '../../../style/addTheme';
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';

export default function HomeMainBannerPart() {
    const {lang} = useGlobalLang();
    
    return (
        <div>
            <VideoWrap>
                <video muted loop autoPlay key={lang} playsInline={true}>
                    <source src={lang === 'en' ? '/v/atoreum_en.mp4' : '/v/atoreum_kr.mp4'}/>
                </video>
            </VideoWrap>
        </div>
    )
}

const VideoWrap = styled.div`
    margin-top: -3%;
    text-align: center;
    & video{
        width: 100%;
    }
    @media ${addTheme.media.m}{
        margin-top: 0;
    }
`;