import React, { useState } from 'react'
import styled from 'styled-components';
import { MaxWidthWrap } from '../../../style/basic/commonStyle';
import TitleItem from '../../common/item/TitleItem';
import TitleSubItem from '../../common/item/TitleSubItem';
import validator from 'validator';
import { addTheme } from '../../../style/addTheme';
import { Checkbox } from '@mui/material';
import { useNavigate } from 'react-router';
import useLang from '../../../utils/custom-hooks/common/useLang';

export default function HomeNewsletterPart() {
    const txt = useLang(7);
    const [email,setEmail] = useState('');
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();

    const chkAction = (chk:any) =>{
        setChecked(chk);
    }
    
    const btnAction = (e:any) =>{
        e.preventDefault();
        const emailChk = validator.isEmail(email);
        if(!emailChk){
            alert(txt[0]);
            return;
        }
        if(!checked){
            alert(txt[1]);
            return;
        }
    }

    const privacyPageLink = () =>{
        navigate('/privacy')
    }
    
    return (
        <div>
            <Wrap>
                <MaxWidthWrap>
                    <InnerWrap>
                        <TitleItem text='Newsletter'></TitleItem>
                        <TitleSubItem text='Subcribe our newsletter and stay up to date about the ATOREUM'></TitleSubItem>
                        <form onSubmit={btnAction}>
                            <InputWrap>
                                <InputItem type='email' required value={email} onChange={(e:any)=>setEmail(e.target.value)} placeholder='Your email address'></InputItem>
                                <Btn type='submit'>Subscribe</Btn>
                            </InputWrap>
                            <ChkWrap>
                                <label>
                                    <Checkbox required checked={checked} onChange={(e:any)=>chkAction(e.target.checked)} sx={{'color':{color:'#7a7a7a'}}}></Checkbox>
                                    <span style={{cursor:'pointer'}}>I agree to receive marketing emails from ATOREUM and accept the </span>
                                </label>
                                <label>
                                    <span onClick={privacyPageLink} style={{cursor:'pointer',textDecoration:'underline'}}>privacy policy.</span>
                                </label>
                            </ChkWrap>
                        </form>
                    </InnerWrap>
                </MaxWidthWrap>
            </Wrap>
        </div>
    )
}

const Wrap = styled.div`
    background-color: #262626;
`;

const InnerWrap = styled.div`
    padding: 60px 0;
`;

const InputWrap = styled.div`
    display: flex;
    margin-top: 30px;
`;

const InputItem = styled.input`
    background-color: #7a7a7a;
    color: #fff;
    padding: 0 10px;
    min-width: 270px;
    &::-webkit-input-placeholder{
        color: #fff;
    }
    @media ${addTheme.media.m}{
        min-width: 200px;
    }
`;

const Btn = styled.button`
    background-color: #00359a;
    color: #fff;
    padding: 14px 24px;
`;

const ChkWrap = styled.div`
    margin-top: 20px;
    & input{
        margin-right: 8px;
    }
    & label{
        font-size: 14px;
        color: ${addTheme.subText.color};
    }
`;

