import React from 'react'
import styled from 'styled-components'
import { MaxWidthWrap, RowBetween } from '../../../style/basic/commonStyle';
import TitleItem from '../../common/item/TitleItem';
import TitleSubItem from '../../common/item/TitleSubItem';
import useLang from '../../../utils/custom-hooks/common/useLang';

export default function HomeContactPart() {
    const txt = useLang(5);
    const contactClick = () =>{
        window.location.href = `mailto:support@atoreum.com`;
    }
    return (
        <div>
            <Wrap>
                <MaxWidthWrap>
                    <InnerWrap>
                        <LeftWrap>
                            <TitleItem text={txt[0]}></TitleItem>
                            <TitleItem text={txt[1]}></TitleItem>
                            <TitleSubItem marginTop={1.5} text={txt[2]}></TitleSubItem>
                            <Btn onClick={contactClick}>Contact Us</Btn>
                        </LeftWrap>
                        <RightWrap>
                            <img alt='contact-img' src='/img/banner_3.png'></img>
                        </RightWrap>
                    </InnerWrap>
                </MaxWidthWrap>
            </Wrap>
        </div>
    )
}

const Wrap = styled.div`
    background-color: #00359a;
`;

const InnerWrap = styled(RowBetween)`
    padding: 60px 0;
`;
const LeftWrap = styled.div`

`;
const RightWrap = styled.div`
    & img{
        max-width: 100%;
        max-height: 340px;
    }
`;

const Btn = styled.button`
    margin-top: 40px;
    padding: 15px 0;
    width: 240px;
    border: 1px solid #fff;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
`;