import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_USER_LOGIN = 'subox-dev/common/SET_USER_LOGIN';
const SET_USER_LOGOUT = 'subox-dev/common/SET_USER_LOGOUT';

export const setUserInfoLoginActionCreator = createAction(SET_USER_LOGIN);
export const setUserInfoLogOutActionCreator = createAction(SET_USER_LOGOUT);

export function setUserInfo(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setUserInfoLoginActionCreator>> {
  return async function (dispatch) {
    try {
      dispatch(setUserInfoLoginActionCreator({
        data: data
      }));
    } catch (error) {
      
    }
  };
}

export interface userInfo {
  "admin_id": number;
  "admin_email": string;
  "admin_level": number;
  "jwt_token": string;
  "admin_code":string;
  "admin_name":string;
}

const initialState: userInfo = {
  "admin_id": 0,
  "admin_email":'',
  "admin_level" : 0,
  "jwt_token":'',
  "admin_code":'',
  "admin_name":'',
}

export const user = handleActions<userInfo, any>({
  [SET_USER_LOGIN]: (state, action: ReturnType<typeof setUserInfoLoginActionCreator>) => ({
      admin_id: action.payload.data.admin_id ?? state.admin_id,
      admin_email: action.payload.data.admin_email ?? state.admin_email,
      admin_level: action.payload.data.admin_level ?? state.admin_level,
      jwt_token: action.payload.data.jwt_token ?? state.jwt_token,
      admin_code: action.payload.data.admin_code ?? state.admin_code,
      admin_name: action.payload.data.admin_name ?? state.admin_name,
    })
,
  [SET_USER_LOGOUT]: (state, action: ReturnType<typeof setUserInfoLogOutActionCreator>) => (initialState),
}, initialState);
