import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_TOAST_OPEN = 'mesh-dash/common/SET_TOAST_OPEN';

export const globalToastActionCreator = createAction(SET_TOAST_OPEN);

export function setGlobalToast(data: any): ThunkAction<void, RootState, null, ReturnType<typeof globalToastActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(globalToastActionCreator({
              data: data
            }));
    } catch (error) {
      
    }
  };
}

export interface ToastState {
    text?: string;
    position?: 'top-right' | 'top-center' | 'top-left' | 'bottom-right' | 'bottom-center' | 'bottom-left';
    autoClose?: number;
    hideProgressBar?: boolean;
    closeOnClick?: boolean;
    pauseOnHover?: boolean;
    draggable?: boolean;
    progress?: any;
    visible?: boolean;
}

const initialState: ToastState = {
    text: '',
    position: "top-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    visible: false
};

export const globalToast = handleActions<ToastState, any>({
  [SET_TOAST_OPEN]: (state, action: ReturnType<typeof globalToastActionCreator>) => ({
    text: action.payload.data.text ?? 'Copy complete.',
    position: action.payload.data.position ?? 'top-right',
    autoClose: action.payload.data.autoClose ?? 1000,
    hideProgressBar: action.payload.data.hideProgressBar ?? false,
    closeOnClick: action.payload.data.closeOnClick ?? true,
    pauseOnHover: action.payload.data.pauseOnHover ?? false,
    draggable: action.payload.data.draggable ?? true,
    progress: action.payload.data.progress ?? undefined,
    visible: true
  }),
}, initialState);
