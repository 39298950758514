import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_SHOW = 'meca-admin/common/SET_DIALOG_SHOW';
const SET_HIDE = 'meca-admin/common/SET_DIALOG_HIDE';

export const setDialogShowCreator = createAction(SET_SHOW);
export const setDialogHideCreator = createAction(SET_HIDE);

export function setDialogData(data: any): ThunkAction<void, RootState, null, ReturnType<typeof setDialogShowCreator>> {
    
    return async function (dispatch) {
        try {
                dispatch(setDialogShowCreator({
                    data: data
                }));
        } catch (error) {
        
        }
    };
}

export interface dialogData {
    title: string;
    content: string;
    open: boolean;
    clickEvent:any;
    leftBtnText:string;
    rightBtnText:string;
    type:string;
    backData:any;
}

const initialState: dialogData = {
    title:'',
    content:'',
    open:false,
    clickEvent:()=>{},
    leftBtnText:'DISAGREE',
    rightBtnText:'AGREE',
    type:'',
    backData:'',
};


export const globalDialog = handleActions<dialogData, any>({
    [SET_SHOW]: (state, action: ReturnType<typeof setDialogShowCreator>) => ({
        title: action.payload.data.title ?? state.title,
        content: action.payload.data.content ?? state.content,
        open: action.payload.data.open ?? state.open,
        clickEvent: action.payload.data.clickEvent ?? state.clickEvent,
        leftBtnText: action.payload.data.leftBtnText ?? state.leftBtnText,
        rightBtnText: action.payload.data.rightBtnText ?? state.rightBtnText,
        type: action.payload.data.type ?? '',
        backData: action.payload.data.backData ?? '',
      }),
    [SET_HIDE]: (state, action: ReturnType<typeof setDialogHideCreator>) => ({
        ...state,
        open:false,
        clickEvent:()=>{}
    }),
  }, initialState);
