import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from './../store';
import {theme} from '../../style/extends/themeColor';

const SET_CUSTOM_THEME = 'mesh-dash/theme/SET_CUSTOM_THEME';

export const themeActionCreator = createAction(SET_CUSTOM_THEME);

export function thunkTheme(themecolor: string): ThunkAction<void, RootState, null, ReturnType<typeof themeActionCreator>> {
    return async function (dispatch) {
    try {
            dispatch(themeActionCreator({
                themecolor: themecolor
            }));
    } catch (error) {
      
    }
  };
}

export interface ThemeState {
    themecolor?: any;
}

const initialState: ThemeState = {
    themecolor: {
      fixblack: '#000',
      fixwhite: '#FFF',
      black: '#fff',
      white: '#000',
      red: 'red',
      lightRed: '#f2bdae',
      signalRed: '#eb493d',
      blue: '#0071BC',
      lightBlue: '#aac5f0',
      signalBlue: '#4590e6',
      gray: 'gray',
      lightmain: '#d7e5f5',
      more: '#969696',
      subtext: '#afb3c4',
      container: '#fafafa',
      navBar: '#FFF',
      actionSheetContainer: '#fff',
      donateActionSheetContainer: '#FFF',
      actionSheetTop: '#FFF',
      svgcolor: '#FFF',
      skyblue : '#70a9bd',
      error: 'red',
      navBarContainer: '#FFF',
      cardContainer: '#fff',
      lightWhite: '#f5f5f5',
      toastColor: '#b7d0f7',
      main: 'rgba(0, 223, 255, 1.0)',
      // main: 'rgba(222, 4, 196, 1.0)',
      mainLight: 'rgba(0, 223, 255, 0.3)',
      submain: 'rgba(11, 160, 249, 1.0)',
      modalContainer: 'rgba(0, 0, 0, 0.8)',
      hover: 'rgba(0, 223, 255, 1.0)',
      state_green: ['rgba(135, 245, 66, 1.0)', 'rgba(113, 240, 34, 1.0)', 'rgba(135, 245, 66, 1.0)'],
      increase: '#1890ff',
      decrease: '#faad14',
      green: 'rgba(135, 245, 66, 1.0)'
  }
};

export const themeColor = handleActions<ThemeState, any>({
  [SET_CUSTOM_THEME]: (state, action: ReturnType<typeof themeActionCreator>) => ({
    themecolor: theme(action.payload.themecolor)
  }),
}, initialState);
