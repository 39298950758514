import {createGlobalStyle, css} from 'styled-components';
import StyledReset from 'styled-reset';
import {MyReset} from './my-reset';

const font = css`
  
  body {
    font-family: "roboto";
    font-size: 15px;
  }
`;

const layout = css`
  
  html, body, #root {
    height: 100%;
  }
  
  #root {
    display: flex;
    flex-direction: column;
  }
`;

const reset = css`
  ${StyledReset};
  ${MyReset};
`;

export const notSelect = css`
  -webkit-user-select: none;
  -moz-user-select: none;
`;

export const GlobalStyle = createGlobalStyle`

  ${reset};
  ${layout};

  a {
    color: inherit;
    display: block;
  }
  
  body {
  // padding: 10px;
    ${notSelect}
    background-repeat: no-repeat;
    background-size: 100% 100%;
    ::-webkit-scrollbar {
      display: none;
    }
    background-color: #000;
    color: #fff;
    font-family: "roboto";
  }
`;

