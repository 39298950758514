import React from 'react'
import { Center, MaxWidthWrap } from '../../../style/basic/commonStyle'
import styled from 'styled-components'
import TitleItem from '../../common/item/TitleItem';
import TitleSubItem from '../../common/item/TitleSubItem';
import { addTheme } from '../../../style/addTheme';
import useLang from '../../../utils/custom-hooks/common/useLang';

export default function HomeOnePart() {
    const txt = useLang(1);
    return (
        <div>
            <MaxWidthWrap>
                <InnerWrap>
                    <TextWrap>
                        <TitleItem text={`${txt[0]}, ATOREUM`}></TitleItem>
                        <SubTextWrap>
                            <TitleSubItem marginTop={0.5} textAlign={'center'} text={txt[1]}></TitleSubItem>
                            <TitleSubItem marginTop={0.5} textAlign={'center'} text={txt[2]}></TitleSubItem>
                            <TitleSubItem marginTop={0.5} textAlign={'center'} text={txt[3]}></TitleSubItem>
                        </SubTextWrap>
                    </TextWrap>
                    <CanvasWrap>
                        <video muted autoPlay loop playsInline={true}>
                            <source src='/v/bg1.mp4'></source>
                        </video>
                        <img alt='logo' src='/img/logo-icon.png'></img>
                    </CanvasWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled.div``;

const TextWrap = styled(Center)`
    flex-direction: column;
`

const SubTextWrap = styled(TextWrap)`
    margin-top: 60px;
`;

const CanvasWrap = styled.div`
    margin-top: 50px;
    position: relative;
    & video{
        max-width: 100%;
    }
    & img{
        position: absolute;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 100px;
    }

    @media ${addTheme.media.m}{
        & img{
            width: 80px;
        }
    }
`;
