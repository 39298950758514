import { BrowserRouter, Route, Routes,Navigate } from 'react-router-dom';
import { useUserInfo } from '../utils/custom-hooks/common/useUserInfo';
import HomePage from '../components/home/page/HomePage';
import PrivacyPage from '../components/privacy/page/PrivacyPage';
import ScrollToTop from '../utils/custom-hooks/common/ScrollToTop';

export default function Router(){
    const user = useUserInfo();
    
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop></ScrollToTop>
            <Routes>
                <Route path='/' Component={HomePage}></Route>
                <Route path='/privacy' Component={PrivacyPage}></Route>

                {/* <Route path='/' Component={MainTest1}></Route> */}
            </Routes>
        </BrowserRouter>
    )
}