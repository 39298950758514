import React from 'react'
import styled from 'styled-components';
import { Row } from '../../../style/basic/commonStyle';
import { Link } from 'react-router-dom';

export const MENU = [
    {id:1,name:'Technology',link:''},
    {id:2,name:'Network',link:''},
    {id:3,name:'Solutions',link:''},
    {id:4,name:'Contact Us',link:''},
]

export default function HeaderMenuPart() {
    return (
        <MenuWrap>
            {/* {MENU.map((data,index)=>{
                return(
                    <MenuItem key={`menu-${index}`}>
                        <Link to={data.link}>{data.name}</Link>
                    </MenuItem>
                )
            })} */}
        </MenuWrap>
    )
}

const MenuWrap = styled(Row)`
    justify-content: space-evenly;
`;
const MenuItem = styled.div`
    font-size: 18px;
`;