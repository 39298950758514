import {createAction, handleActions} from 'redux-actions';
import {ThunkAction} from 'redux-thunk';
import {RootState} from '../store';

const SET_LOADING_TRUE = 'mesh-dash/common/SET_LOADING_TRUE';
const SET_LOADING_FALSE = 'mesh-dash/common/SET_LOADING_FALSE';

export const setLoadingTrueActionCreator = createAction(SET_LOADING_TRUE);
export const setLoadingFalseActionCreator = createAction(SET_LOADING_FALSE);

export interface LoadingState {
    loading: boolean;
}

const initialState: LoadingState = {
    loading: false,
};

export const globalLoadingState = handleActions<LoadingState, any>({
    [SET_LOADING_TRUE]: () => ({
    loading: true
}),
[SET_LOADING_FALSE]: () => ({
    loading: false
}),
}, initialState);
