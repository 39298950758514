import React, { useEffect, useState } from 'react'
import { Center, MaxWidthWrap, Row } from '../../../style/basic/commonStyle'
import TitleItem from '../../common/item/TitleItem'
import styled from 'styled-components';
import CountItem from '../item/CountItem';
import { addTheme } from '../../../style/addTheme';
import useLang from '../../../utils/custom-hooks/common/useLang';

export default function HomeCountPart() {
    const txt = useLang(6);
    const [blockCount,setBlockCount] = useState(185248);
    const [walletCount,setWalletCount] = useState(1540);
    const [networkCount,setNetWorkCount] = useState(17);

    useEffect(()=>{
        const timer = setInterval(()=>{
            setBlockCount(count => count+1);
            setNetWorkCount(count => count+1);
        },1000);
        return () => clearInterval(timer);
    },[])

    useEffect(()=>{
        const timer = setInterval(()=>{
            setWalletCount(count => count+1);
        },500);
        return () => clearInterval(timer);
    },[])

    return (
        <div>
            <MaxWidthWrap>
                <Center>
                    <TitleItem text={txt[0]}></TitleItem>
                </Center>
                <InnerWrap>
                    <ContentWrap>
                        <LeftWrap>
                            <img alt='count-img' src='/img/asset_count.png'></img>
                        </LeftWrap>
                        <RightWrap>
                            <CountItem count={blockCount} title={txt[1]}></CountItem>
                            <CountItem count={walletCount} title={txt[2]} mt={40}></CountItem>
                            <CountItem count={networkCount} title={txt[3]} mt={40}></CountItem>
                        </RightWrap>
                    </ContentWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const ContentWrap = styled(Row)`
    align-items: start;
    flex-wrap: wrap;
`;

const InnerWrap = styled.div`
    margin-top: 100px;
`;
const LeftWrap = styled.div`
    width: 55%;
    & img{
        max-width: 100%;
    }
    @media ${addTheme.media.m}{
        width: 100%;
    }
`;
const RightWrap = styled.div`
    width: 40%;
    margin-left: 5%;
    margin-top: 25px;
    @media ${addTheme.media.m}{
        width: 100%;
        margin-left: 0%;
        margin-top: 55px;
    }
`;