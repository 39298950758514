import React from 'react'
import { MaxWidthWrap } from '../../../style/basic/commonStyle'
import styled from 'styled-components';

export default function ContentPart() {
    return (
        <div>
            <MaxWidthWrap>
            <pre style={{whiteSpace:'pre-wrap'}} dangerouslySetInnerHTML={{ __html: `
Privacy Policy
Last updated: April 7, 2022

Thank you for choosing to be part of our community at ATOREUM Labs. (together with our subsidiaries and affiliates, “Team”, “we”, “us”, or “our”). We are committed to protecting your personal information. This Privacy Policy applies to all personal information collected through our platform, mobile applications, products and websites, including atoreum.com and related sites, and any related services, sales, marketing or events (collectively, the “Services”). 

When you access or use our Services, you trust us with your personal information. In this Privacy Policy, we describe how we collect, use, store and share your personal information and what rights you have in relation to it. If there are any terms in this privacy policy that you do not agree with, please discontinue access and use of our Services.

Please read this Privacy Policy carefully as it will help you make informed decisions about sharing your personal information with us. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at the email in the “Contact us” section.

‍
Contents

1. What kinds of information do we collect?
2. How do we use this information?
3. How do we share this information?
4. International transfers
5. Retention
6. How do we secure this information?
7. Minors
8. Your choices
9. Changes to this Privacy Policy
10. Contact us
11. Notice to California residents
12. Notice to Nevada residents
13. Notice for EEA
 

1. What kinds of information do we collect?

Information you provide us

We collect personal information that you provide us through the Services, including when you use the Services, express an interest in obtaining information about our products or services, participate in activities and events related to the Services or otherwise contact us. The personal information that we collect depends on the context of your interactions with us, the choices you make, the Services and the products and features you use, such as:

Contact information. Email address, mailing address and phone number.
Usage information. Information about how you use the Services, such as the products and features you use and actions you take.
Marketing data. Your preferences for receiving our marketing communications and details about how you engage with them.
User communications. Your communications when you contact us with questions or feedback, including feedback about the Services and customer support correspondences.
Payment and financial information. Credit card information and other financial information when you make purchases or payments and related transaction information. 
Technical data. Blockchain wallet address and network transactions.
 

Information we automatically collect

We automatically collect certain information when you access, use, download or interact with the Services, such as:

Device data. Browser and device characteristics, operating system, IP address, public blockchain wallet address and language preferences.
Usage data. URLs, country, location, how and when you use the Services and other technical information.
Transaction data. Information about your purchase activity or other transactions, such as product details, purchase price, and the date and location of the transaction.
Cookies. We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy. 
 

We use Google Analytics to help us offer you an optimized user experience. You can find more information about Google Analytics’ use of your personal data here.



2. How do we use this information?

To provide and improve our service

We use your personal information to:

Enable you to access and use the Services.
Provide and deliver products and services that you may request.
Maintain and improve our products and services.
Personalize your experience on the Services.
Process and complete transactions, and send you related information, including purchase confirmations and ticketing information.
Facilitate events and provide information about events that you indicate an interest in.
Send information, including technical notices, updates, security alerts and administrative messages.
Provide support for the Services, including resolving issues and responding to your questions and feedback.
 

To comply with the law

In some cases, we have a legal obligation to collect or use your personal information. We use your personal information as we believe necessary or appropriate to:

Comply with applicable laws, including anti-money laundering (AML) laws and know-your-customer (KYC) requirements.
Comply with lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.
 

Compliance, fraud prevention and safety

We use your personal information as we believe necessary or appropriate to:

Prevent, detect and investigate fraudulent, harmful or illegal activity, including cyberattacks and scams.
Enforce the Terms of Use that govern the Services.
Comply with our internal policies and procedures.
 

To send marketing communications

We and our third party marketing partners may use your personal information for marketing purposes, such as to send you communications (including via text message and/or email as permitted by law) about promotions, upcoming events, and other news about products and services offered by us. You can opt-out of our marketing and promotional communications at any time (see the “Your choices” section).

 

Targeted advertising

We engage third party advertising partners to display ads to you. Our advertising partners may use cookies and similar technologies to collect information about your online activity for the purpose of targeting ads to you. You can learn more about how to opt-out of tracking technologies in the “Your choices” section.

 

Research and analytics

We may use your information for data analysis, including identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our services, products, marketing and your experience. We may use, store and share this information in aggregated or anonymized form so that it is not associated with individual end users and does not include personal information.

 

With your consent

We may use or share your personal information with your consent, such as when you consent to let us post your testimonials or endorsements on our Services, you instruct us to take a specific action with respect to your personal information, or you opt into third party marketing communications.

 

3. How do we share this information?

We do not share your personal information with anyone else without your express consent, except as described in this Privacy Policy. We disclose personal information to third parties under the following circumstances:

 

Service providers and professional advisors

Companies and individuals that provide services on our behalf or help us operate the Services, such as payment processing, customer support, marketing, hosting, event organization, logistics planning, analytics, email delivery, database management services and identity verification services, as well as lawyers, bankers, auditors, and insurers, in the course of the professional services that they render to us.

 

Compliance with laws and law enforcement; protection and safety

For legal, protection and safety purposes, such as to:

Comply with laws, including KYC and AML requirements.
Respond to lawful requests and legal processes.
Protect the rights and property of the Team, our agents, customers, and others. This includes enforcing our agreements, policies and terms of use.
Protect the safety of our employees and agents, our customers or any person.
 

Affiliates

We may share your personal information with our subsidiaries and corporate entities affiliated for purposes consistent with this Privacy Policy.

 

Business transactions

When we enter into a business transaction, or negotiate a business transaction, involving a corporate divestiture, merger, consolidation, acquisition, reorganization, bankruptcy, sale or other transfer of all or a part of our business or assets.

 

Event partners

We may share your personal information with co-organizers, co-hosts and co-sponsors of any events, such as conferences, webinars, hackathons, whether in-person or virtual, that we organize, host or sponsor.

 

Other users

Certain of our Services enable you to connect to and interact with other users of those Services. If you access or use such Services, we may share your personal information with such other users.

 

Other

You may permit us to share your personal information with other companies or entities of your choosing. Those uses will be subject to the privacy policies of the recipient entities. 

We may also share aggregated and/or anonymized data with others for their own uses.

 

4. International transfers

We and our service providers have offices in the United States and in other countries. As such, your personal information may be processed in, transferred to or from the United States or other locations outside of your state, province, country or other governmental jurisdiction where data protection laws may not be as protective or comprehensive as those in your jurisdiction.

If you are a European user, please review the “Notice for EEA” section.

 

5. Retention

We retain personal information for as long as it is necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law, such as tax, accounting, fraud prevention, to assist in investigations, to resolve disputes, to establish or defend legal claims or other legal requirements.

In some circumstances we may anonymize your personal information such that it can no longer be associated with you, in which case we may use this information indefinitely without further notice to you.

 

6. How do we secure this information?

We employ appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, the security of information transmitted through the internet can never be guaranteed. We are not responsible for any interception or interruption of any communications through the internet or for changes to or losses of data. Users of the Services are responsible for maintaining the security of any password, biometrics, user ID or other form of authentication involved in obtaining access to password protected or secure areas of any of our digital services. In order to protect you and your data, we may suspend your use of any of the Services, without notice, pending an investigation, if any breach of security is suspected.

 

7. Minors

We do not knowingly solicit data from or market to persons under the age of 18. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at privacy@atoreum.com.

 

8. Your choices

Access, update, correct or delete

You may contact us to request a copy of your personal information. You may also request that we update, correct or delete your personal information or that we update your preferences. We are permitted to request additional information from you to verify your identity and authority to delete the account.

 

Opt out of marketing communications

You may opt out of receiving newsletters, promotional emails and other marketing communications by following the instructions in such communications we send you.

 

Opt out of sharing information with third parties

You may opt out of sharing your personal information with third parties by contacting us.

 

Opt out of tracking technologies generally

Regular cookies may generally be disabled through the preferences and settings of most web browsers. For more information, please see our Cookie Policy.

 

Opt out of Google Analytics

You may exercise choices regarding the use of cookies from Google Analytics by going to https://tools.google.com/dlpage/gaoptout and downloading the Google Analytics Opt-out Browser Add-on. Your use of such add-ons is subject to Google’s terms of use.

 

Do Not Track

Most web browsers and some mobile operating systems and mobile applications include a Do Not Track (“DNT”) feature setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. We do not currently respond to DNT browser signals.

 

9. Changes to this Privacy Policy

We may update this Privacy Policy from time to time. If we make any changes, we will change the Last Updated date above. We encourage you to periodically review the Privacy Policy for the latest information on our privacy practices.

Any modifications to this Privacy Policy will be effective upon our posting of the updated terms. In all cases, your continued use of the Services following the posting of any updated Privacy Policy indicates your acceptance of the terms of the updated Privacy Policy.

 

10. Contact us

We welcome your comments or questions about this Privacy Policy. You may contact us at privacy@atoreum.com. 

 

11. Notice to California residents

California Civil Code Section 1798.83 permits individual California residents to request certain information regarding our disclosure of certain categories of personal information to third parties for those third parties’ direct marketing purposes. To make such a request, please contact us using the information in the “Contact Us” section. This request may be made no more than once per calendar year.

If you are a California resident, you have certain additional rights with respect to your personal information pursuant to the California Consumer Privacy Act of 2018 (“CCPA”). 

We are required to inform you of:

What categories of information we may collect about you, including during the preceding 12 months: See the section “What kinds of information do we collect?”.
The purposes for which we may use your personal information, including during the preceding 12 months: See the section “How do we use this information?”.
The purposes for which we may share your personal information, including during the preceding 12 months: See the section “How do we share this information?”. In addition, we may share the following categories of information with the following parties: 
Identity information: Service providers such as KYC service providers.
Contact information: Marketing partners and event partners.
Financial information: Service providers such as payment service providers and logistics providers.
In the preceding 12 months, we have not sold any personal information of consumers.
‍

Your rights

You have the right to request to know: (i) the categories of personal information we have collected about you in the last 12 months; (ii) the specific pieces of personal information we have about you; (iii) the categories of sources from which that personal information was collected; (iv) the categories of your personal information that we sold or disclosed in the last 12 months; (v) the categories of third parties to whom your personal information was sold or disclosed in the last 12 months; and (vi) the purpose for collecting and selling your personal information. These rights are subject to limitations as described in the CCPA. We may deny your request if we need to do so to comply with our legal rights or obligations.

We will not discriminate against any consumer for exercising their CCPA rights.

You may exercise these rights yourself or you may designate an authorized agent to make these requests on your behalf. To protect your information, we may need to verify your identity before processing your request, including by collecting additional information to verify your identity, such as government issued identification documents. We will not fulfill your request unless you have provided sufficient information for us to reasonably verify you are the individual about whom we collected personal information. We will only use the personal information provided in the verification process to verify your identity or authority to make a request and to track and document request responses, unless you initially provided the information for another purpose. When we verify your agent’s request, we may verify your identity and request a signed document from your agent that authorizes your agent to make the request on your behalf. To protect your personal information, we reserve the right to deny a request from an agent that does not submit proof that they have been authorized by you to act on their behalf.

If you would like to exercise any of these rights, please contact us at the email in the “Contact us” section.

‍

12. Notice to Nevada residents

If you are a Nevada resident, you have the right to opt out of the sale of certain personal information to third parties. At this time, we do not sell your information to third parties. If that were to change, we will update this Privacy Policy.

‍

13. Notice for EEA

If you are a data subject in the European Economic Area, the United Kingdom or Switzerland, you have certain rights with respect to your personal data pursuant to the General Data Protection Regulation of the European Union (“GDPR”). This section applies to you.

References to “personal information” in this Privacy Policy are equivalent to “personal data” as defined under GDPR.

Team is the controller of your personal information as covered by this Privacy Policy for purposes of GDPR. Team’s address is as follows: Ava Labs, Inc., 1177 Avenue of the Americas, 5th Floor, Suite 50922, New York, NY 10036, USA.

We process your personal data in reliance on the legal bases below. Where the purpose of processing is:

To provide and improve our service: We process your personal data as necessary to perform the contract under which we provide our Services or to take steps that you request prior to signing up for the Services.
To comply with the law: We process your personal data as necessary to comply with applicable laws and our legal obligations.
For compliance, fraud prevention and safety: We have a legitimate interest in processing your personal data, as described in this Privacy Policy, and our reasons for doing so outweigh any prejudice to your data protection rights. We also process your personal data as necessary to comply with our legal obligations.
To send marketing communications; for targeted advertising; or for research and analytics: We have a legitimate interest in processing your personal data, as described in this Privacy Policy, and our reasons for doing so outweigh any prejudice to your data protection rights.
With your consent: We process your personal data in reliance on your consent. You may withdraw it any time in the manner indicated when you provided consent or in the Services.
‍

Your rights

You may: (i) ask whether we have any personal data about you and request a copy of such personal data; (ii) request that we update or correct inaccuracies in your personal data; (iii) request that we delete your personal data; (iv) request a portable copy of your personal data; (v) request that we restrict the processing of your personal data if such processing is inappropriate; and (vi) object to our processing of your personal data. These rights are subject to applicable law. 

If you would like to exercise any of these rights, please contact us at the email in the “Contact us” section. To protect your information, we may need to verify your identity before processing your request, including by collecting additional information to verify your identity, such as government issued identification documents.

If you would like to submit a complaint about our use of your personal data or our response to your requests regarding your personal data, please contact us at the same email. You may also lodge a complaint with your local data protection authority.

‍

Transfers outside of the EEA

If we transfer your personal data outside the European Economic Area, we will do so in accordance with the terms of this Privacy Notice and applicable data protection law.
            
            `}}></pre>
            </MaxWidthWrap>
        </div>
    )
}

const Test = styled.div`
    width: 100%;
    display: block;
`;