import styled from 'styled-components';
import { addTheme } from '../addTheme';

export const MaxWidthWrap = styled.div<{maxWidth?:string,margin?:string, padding?:string}>`
    width:100%;
    height: 100%;
    max-width: ${props=>props.maxWidth ?? addTheme.maxWidth};
    margin: ${props=>props.margin ?? '0 auto'};
    padding: ${props=>props.padding ?? 0};
    @media (max-width:1200px){
        padding: ${props=>props.padding ?? addTheme.padding};
    }
`;

export const Center = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const CenterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
`;

export const RowBetween = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

export const Left = styled.div`
    display: flex;
    align-items: left;
    justify-content: center;
`;

export const PaddingWrap = styled.div<{padding?: number}>`
    padding: ${props => props.padding ?? 0}px;
`;

export const FullWrap = styled.div<{padding?: number}>`
    width: 100%;
    height: 100%;
    padding: ${props => props.padding ?? 0}px;
`;

export const JustifyWrap = styled.div<{height?: string}>`
    justify-content: space-between;
    flex-direction: column;
    height: ${props => props.height ?? undefined};
`;

export const ItemDiv = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: ${props => props.center ? 'center' : 'flex-start'};
    align-items: center;
`;

export const ItemDivBetween = styled.div<{width?: number, center?: boolean, height?: number}>`
    height: ${props => props.height ?? 100}%;
    max-height: ${props => props.height ?? 100}%;
    width: ${props => props.width ?? 20}%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export const IconImage = styled.img<{size?: number}>`
    width: ${props => props.size ?? 50}px;
    height: ${props => props.size ?? 50}px;
`;

export const WidthWrap = styled.div<{width?: number, align?: string}>`
    width: ${props => props.width}%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: ${props => props.align ?? 'center'};
    flex-direction: column;
`;