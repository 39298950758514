import React from 'react'
import HeaderPage from '../../header/page/HeaderPage'
import FooterPage from '../../footer/page/FooterPage'
import ContentPart from '../part/ContentPart'

export default function PrivacyPage() {
    return (
        <div>
            <HeaderPage></HeaderPage>
            <ContentPart></ContentPart>
            <FooterPage></FooterPage>
        </div>
    )
}


