import React from 'react';
import Modal from 'react-modal';
import styled, {keyframes} from 'styled-components';
import { useGlobalLoadingState } from '../../../utils/custom-hooks/common/useGlobalLoadingState';
import { useTheme } from '../../../utils/custom-hooks/common/useTheme';

export default function GlobalLoadingModalPage(){

    const globalLoadingState = useGlobalLoadingState();
    const theme = useTheme();

    return (
        <>
         <ModalWrap
            isOpen={globalLoadingState}
            // contentLabel="Example Modal"
            ariaHideApp={false}
            theme={theme}>
                <RingWrap theme={theme}>
                    <RotateDot theme={theme}/>
                </RingWrap>
        </ModalWrap>
        </>
    )
}

const ModalWrap = styled(Modal)<{theme?: any}>`
    width: 100%;
    height: 100%;
    position: absolute;
    background: ${props => props.theme.modalContainer ?? 'rgba(0, 0, 0, 0.8)'};
    align-items: center;
    justify-content: center;
    z-index: 100;
`;

const animateC = keyframes`
    0% {
        transform:rotate(0deg);
    }
    100% {
        transform:rotate(360deg);
    }
`;

const animate = keyframes`
    0% {
        transform:rotate(45deg);
    }
    100% {
        transform:rotate(405deg);
    }
`;

const RingWrap = styled.div<{theme?: any}>`
position:absolute;
top:50%;
left:50%;
transform:translate(-50%,-50%);
width: 150px;
height: 150px;
background:transparent;
border:2px solid ${props => props.theme.mainLight ?? 'rgba(0, 223, 255, 0.1)'};
border-radius:50%;
text-align:center;
color: ${props => props.theme.main};
display: flex;
align-items: center;
justify-content: center;

box-shadow:0 0 20px rgba(0,0,0,.5);

    &:before {
        content:'';
        position:absolute;
        top:-0px;
        left:-0px;
        width:100%;
        height:100%;
        border:3px solid transparent;
        border-top:3px solid ${props => props.theme.main};
        border-right:3px solid ${props => props.theme.main};
        border-radius:50%;
        animation: ${animateC} 2s linear infinite;
    }
`;

const RotateDot = styled.span<{theme?: any}>`
    display:block;
    position:absolute;
    top:calc(50% - 2px);
    left:50%;
    width:50%;
    height:4px;
    background:transparent;
    transform-origin:left;
    animation: ${animate} 2s linear infinite;

    &:before {
        content:'';
        position:absolute;
        width:12px;
        height:12px;
        border-radius:50%;
        background: ${props => props.theme.main};
        top:-8px;
        right:-4px;
        box-shadow:0 0 20px ${props => props.theme.main};
    }
`;
