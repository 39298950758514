import React from 'react'
import styled from 'styled-components';
import { Parallax } from 'react-parallax';

export default function HomeBannerPart() {
    return (
        <div>
            <Parallax  bgImage="/img/banner_1.png" bgImageAlt='city' style={{height:'400px'}} strength={-400}></Parallax>
            {/* <Content></Content> */}
        </div>
    )
}