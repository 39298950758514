import React from 'react'
import { MaxWidthWrap } from '../../../style/basic/commonStyle'
import styled from 'styled-components';
import TitleItem from '../../common/item/TitleItem';
import TitleSubItem from '../../common/item/TitleSubItem';
import {HiOutlineArrowNarrowRight} from 'react-icons/hi'
import useLang from '../../../utils/custom-hooks/common/useLang';
import { addTheme } from '../../../style/addTheme';

export default function HomeEasyConnectPart() {
    const txt = useLang(2);

    return (
        <div>
            <MaxWidthWrap>
                <InnerWrap>
                    <LeftWrap>
                        <TitleItem text={txt[0]}></TitleItem>
                        <TitleItem text={txt[1]}></TitleItem>
                        <TitleItem text={txt[2]}></TitleItem>
                    </LeftWrap>
                    <RightWrap>
                        <TitleSubItem text={txt[3]}></TitleSubItem>
                        <TitleSubItem marginTop={1} text={`${txt[4]} ${txt[5]}`}></TitleSubItem>
                        <ArrowWrap>
                            MORE
                            <HiOutlineArrowNarrowRight style={{marginLeft:'8px'}} size={26}></HiOutlineArrowNarrowRight>
                        </ArrowWrap>
                    </RightWrap>
                </InnerWrap>
            </MaxWidthWrap>
        </div>
    )
}

const InnerWrap = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${addTheme.media.m}{
        &>div{
            width: 100%;
        }
    }
`;

const LeftWrap = styled.div`
    flex-shrink: 0;
    width: 35%;
`;
const RightWrap = styled.div`
    width: 65%;
    @media ${addTheme.media.m}{
        margin-top: 30px;
    }
`;

const ArrowWrap = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
`;
