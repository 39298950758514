import React, { useState } from 'react'
import styled from 'styled-components'
import { Center, Row } from '../../../style/basic/commonStyle'
import { useGlobalLang } from '../../../utils/custom-hooks/common/useGlobalLang';
import { setGlobalLang } from '../../../store/common/globalLang';
import { useDispatch } from 'react-redux';


export default function HeaderLangPart() {
    const dispatch = useDispatch();
    const {lang} = useGlobalLang();
    
    // 언어 클릭
    const langChange = (la:string) =>{
        dispatch(setGlobalLang({
            data: la,
        }))
    }

    return (
        <div>
            <Wrap>
                <Item activeLang = {lang === 'en'} onClick={()=>langChange('en')}>ENG</Item>
                <BlockItem></BlockItem>
                <Item activeLang = {lang === 'ko'} onClick={()=>langChange('ko')}>KOR</Item>
            </Wrap>
        </div>
    )
}

const Wrap = styled(Row)`
    width: 84px;
`;

const Item = styled(Center)<{activeLang:boolean}>`
    color: ${props => props.activeLang ? '#fff' : '#7f7f7f'};
    font-weight: ${props => props.activeLang ? '700' : '400'};
    cursor: pointer;
`;

const BlockItem = styled(Center)`
    width: 2px;
    height: 16px;
    background-color: #7f7f7f;
    margin: 0 8px;
`;